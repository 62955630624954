<template>
  <div>
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      :label-position="'top'"
      class="flex flex-1 "
    >
      <div class="w-full">
        <el-form-item class="w-full" label="配件信息" required>
          <el-input
            v-model="ruleForm.title"
            type="text"
            maxlength="50"
            placeholder="请输入配件名称或OE号，多个请用“，”分割"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full" label="品质要求" required>
          <el-select
            v-model="ruleForm.qualities"
            multiple
            placeholder="请选择品质要求"
          >
            <el-option
              v-for="item in qualitiesOpts"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="w-full" label="品牌要求">
          <el-select
            v-model="ruleForm.brands"
            multiple
            placeholder="请选择品牌要求"
          >
            <el-option
              v-for="item in brandsOpts"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="w-full" label="详细说明">
          <el-input
            v-model="ruleForm.detail"
            type="textarea"
            :rows="2"
            maxlength="50"
            placeholder="请输入详细说明"
          ></el-input>
        </el-form-item>
        <el-form-item class="w-full" label="上传图片(最多上传10张)">
          <UploadFileImg
            :maxSize="1"
            listType="picture-card"
            :limit="10"
            :fileList="imgList"
            :isMultiple="true"
            :showFileList="true"
            :hideFlag="true"
            @getFileList="(fileList) => getFileList(fileList)"
          />
        </el-form-item>
      </div>
    </el-form>
    <div class=" flex justify-end space-x-16 border-t pt-8">
      <div
        class="border px-16 py-2 rounded-full border-gray-900  cursor-pointer"
        @click="cancelFn"
      >
        取消
      </div>
      <div
        class="border px-16 py-2 rounded-full border-warning bg-warning text-white cursor-pointer"
        @click="publishFn"
      >
        确定
      </div>
    </div>
  </div>
</template>

<script>
import UploadFileImg from "@/components/Upload/UploadFileImg";
import { getBrands, getDict } from "@/api/basic";
import { publishNeedApi } from "@/api/need";

export default {
  components: {
    UploadFileImg,
  },
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgList: [],
      ruleForm: {
        title: "",
        qualities: [],
        brands: [],
        detail: "",
        pictures: [],
      },
      qualitiesOpts: [],
      brandsOpts: [],
      rules: {
        title: [
          {
            required: true,
            message: "请输入配件名称或OE号，多个请用“，”分割",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    imgList: {
      handler: function(newVal) {
        this.ruleForm.pictures =
          newVal && newVal.length > 0
            ? newVal.map((item) => item.origin || item)
            : [];
      },
      deep: true,
    },
    formData: {
      handler(val) {
        if (val) {
          this.ruleForm = { ...val };
          this.imgList = val.pictures.map((item) => {
            return {
              uid: item,
              url: item,
              origin: item,
            };
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getBrandsFn();
    this.getDictFn();
  },
  mounted() {
    if (Object.keys(this.formData).length) {
      this.ruleForm = { ...this.formData };
      this.imgList = this.formData.pictures.map((item) => {
        return {
          uid: item,
          url: item,
          origin: item,
        };
      });
    }
  },
  methods: {
    getDictFn() {
      getDict({ type: "quality" }).then((res) => {
        this.qualitiesOpts = res || [];
      });
    },
    getBrandsFn() {
      getBrands().then((res) => {
        this.brandsOpts = [...res];
      });
    },
    getFileList(fileList) {
      this.imgList = fileList;
      // this.ruleForm.pictures = fileList;
    },
    publishFn() {
      if (!this.ruleForm.title) {
        this.$message.error("配件信息不能为空");
        return;
      }
      // if (!this.ruleForm.detail) {
      //   this.$message.error("详细说明不能为空");
      //   return;
      // }
      if (!this.ruleForm.qualities.length) {
        this.$message.error("品质要求不能为空");
        return;
      }
      // if (!this.ruleForm.brands.length) {
      //   this.$message.error("品牌要求不能为空");
      //   return;
      // }
      // if (!this.ruleForm.pictures.length) {
      //   this.$message.error("图片不能为空");
      //   return;
      // }
      publishNeedApi(this.ruleForm).then(() => {
        this.$message.success("发布成功");
        this.resetFn();
        this.$emit("publish");
      });
    },
    cancelFn() {
      this.resetFn();
      this.$emit("cancle");
    },
    resetFn() {
      this.imgList = [];
      this.ruleForm = {
        title: "",
        qualities: [],
        brands: [],
        detail: "",
        pictures: [],
      };
    },
  },
};
</script>
