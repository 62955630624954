<template>
  <div class="needList">
    <el-checkbox v-model="showCompleted" @change="changeCheckboxFn"
      >屏蔽已结束的求购信息</el-checkbox
    >
    <div class="content">
      <div
        class="space-y-8 border border-gray-300 rounded p-16 mt-8"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="">
          <div class="flex justify-between">
            <div class="sapce-x-8 flex justify-start items-center">
              <span
                class="w-8 h-8 rounded-full  inline-block mr-8"
                :class="[
                  item.status == 'looking' ? 'bg-success' : 'bg-gray-900',
                ]"
              ></span>
              <span v-if="item.status == 'looking'">求购中</span>
              <span v-if="item.status == 'completed'">已结束</span>
            </div>
            <div class="space-x-8">
              <el-tooltip content="再次发布" placement="top">
                <i
                  class="iconfont icon-a-asktobuy icon_btn"
                  @click="releaseAgainFn(item)"
                ></i>
              </el-tooltip>
              <el-tooltip content="结束" placement="top">
                <i
                  class="iconfont icon-line_over icon_btn_del"
                  v-if="item.status == 'looking'"
                  @click="endFn(item)"
                ></i>
              </el-tooltip>
              <el-tooltip content="删除" placement="top">
                <i
                  class="iconfont icon-line_delete icon_btn_del"
                  @click="deleteFn(item)"
                ></i>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div>配件信息：{{ item.title }}</div>
        <div>
          <template v-if="item.qualities && item.qualities.length">
            <span
              class="text-warning bg-warning bg-opacity-30 h-16 rounded-l-full rounded-r-full px-8 mr-4"
              v-for="(quality, index_q) in item.qualities"
              :key="'q' + index_q"
            >
              {{ quality }}
            </span>
          </template>
          <template v-if="item.brands && item.brands.length">
            <span
              class="text-band bg-band bg-opacity-30 h-16 rounded-l-full rounded-r-full px-8 mr-4"
              v-for="(brand, index_b) in item.brands"
              :key="'b' + index_b"
            >
              {{ brand }}
            </span>
          </template>
        </div>
        <div class="text-gray-900" v-if="item.detail">
          {{ item.detail }}
        </div>
        <div>
          <template v-for="(img, index_i) in item.pictures">
            <el-image
              style="width: 80px; height: 80px"
              class="mr-8"
              :src="img"
              :key="'img' + index_i"
              :fit="'fill'"
              :preview-src-list="item.pictures"
            ></el-image>
          </template>
        </div>
        <div class="space-x-8">
          <span class="text-gray-900">{{ item.createdAt }}</span>
          <span class="text-warning">{{ item.replyCount }}回复</span>
        </div>
      </div>
      <div class="noData" v-if="!list.length">暂无数据</div>
    </div>
    <el-pagination
      class="mt-8"
      style="text-align:right;"
      layout="total,prev, pager, next"
      :current-page="pageInfo.page"
      :page-size="pageInfo.pageSize"
      :total="pageInfo.totalSize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { myNeedApi, operateNeedApi } from "@/api/need";

export default {
  name: "needList",
  props: {
    keyword: String,
    type: String,
  },
  data() {
    return {
      showCompleted: false,
      pageInfo: {
        page: 1,
        pageSize: 20,
        totalSize: 0,
      },
      list: [],
    };
  },
  mounted() {
    // this.loadList();
  },
  methods: {
    changeCheckboxFn() {
      this.loadList();
    },
    loadList() {
      let params = {
        page: this.pageInfo.page,
        pageSize: this.pageInfo.pageSize,
        keywords: this.keyword,
        type: this.type == 0 ? null : this.type,
        showCompleted: !this.showCompleted,
      };
      myNeedApi(params).then((res) => {
        if (res) {
          this.list = res.rows || [];
          this.pageInfo.totalSize = res.totalSize;
        }
      });
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.loadList();
    },
    endFn(item) {
      let params = {
        id: item.id,
        op: "completed",
      };
      operateNeedApi(params).then(() => {
        this.$message.success("已成功结束");
        this.loadList();
      });
    },
    releaseAgainFn(item) {
      let { title, qualities, brands, detail, pictures } = item;
      let params = {
        title,
        qualities,
        brands,
        detail,
        pictures,
      };
      this.$emit("again", params);
      // let params = {
      //   id: item.id,
      //   op: "repost",
      // };
      // operateNeedApi(params).then(() => {
      //   this.$message.success("再次发布成功");
      //   this.loadList();
      // });
    },
    deleteFn(item) {
      let _this = this;
      _this
        .$confirm("确认删除该条求购信息?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let params = {
            id: item.id,
            op: "delete",
          };
          operateNeedApi(params).then(() => {
            _this.$message.success("删除成功");
            _this.loadList();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.needList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow: auto;
    .icon_btn {
      padding: 4px;
      background: #ffeeeb;
      border-radius: 50%;
      font-size: 16px;
      color: #ff6e4c;
      cursor: pointer;
    }
    .icon_btn_del {
      padding: 4px;
      background: #f4f4f4;
      border-radius: 50%;
      font-size: 16px;
      color: #999999;
      cursor: pointer;
    }
  }
  .noData {
    text-align: center;
    color: @text-color-hint;
  }
}
</style>
