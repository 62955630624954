<template>
  <div class="need relative">
    <el-input
      style="width:25%;"
      size="mini"
      placeholder="请输入关键字"
      v-model="keyword"
    >
      <i
        slot="suffix"
        class="el-input__icon el-icon-search cursor-pointer"
        @click="onSearch"
      ></i>
    </el-input>
    <div class=" absolute right-8 top-12 flex items-center z-50">
      <div class="font-bold m-r-32">
        <div class="text-base text-black-200">
          我有没找到的配件，我要发布求购信息到求购专区
        </div>
        <div class="text-base text-warning">让全国配件商为我服务！</div>
      </div>
      <div
        class=" rounded-full text-white bg-warning py-4 px-8 cursor-pointer"
        @click="publish"
      >
        发布求购
      </div>
    </div>
    <div class="flex-1 relative">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部求购" name="all">
          <NeedList
            ref="needall"
            type="0"
            :keyword="keyword"
            @again="releaseAgainFn"
          />
        </el-tab-pane>
        <el-tab-pane label="有回复" name="hasReply">
          <NeedList
            ref="needreply"
            type="hasReply"
            :keyword="keyword"
            @again="releaseAgainFn"
          />
        </el-tab-pane>
        <el-tab-pane label="无回复" name="noReply">
          <NeedList
            ref="neednoReply"
            type="noReply"
            :keyword="keyword"
            @again="releaseAgainFn"
          />
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      title="发布求购"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="closeFn"
      :width="'35%'"
    >
      <PublishInfo
        ref="publish"
        :formData="formData"
        @cancle="dialogVisible = false"
        @publish="publishFn"
      />
    </el-dialog>
  </div>
</template>
<script>
import PublishInfo from "./components/PublishInfo.vue";
import NeedList from "./components/needList.vue";
export default {
  components: {
    NeedList,
    PublishInfo,
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "all",
      keyword: "",
      formData: {},
    };
  },
  mounted() {
    this.handleClick({ name: "all" });
  },
  methods: {
    releaseAgainFn(data) {
      this.formData = { ...data };
      this.dialogVisible = true;
    },
    closeFn() {
      this.$refs.publish.resetFn();
    },
    onSearch() {
      setTimeout(() => {
        this.handleClick({ name: this.activeName });
      }, 200);
    },
    handleClick(tab) {
      // console.log(tab.name);
      switch (tab.name) {
        case "all":
          if (this.$refs.needall) this.$refs.needall.loadList();
          break;
        case "hasReply":
          if (this.$refs.needreply) this.$refs.needreply.loadList();
          break;
        case "noReply":
          if (this.$refs.neednoReply) this.$refs.neednoReply.loadList();
          break;
      }
    },
    publish() {
      this.dialogVisible = true;
    },
    publishFn() {
      this.dialogVisible = false;
      this.handleClick({ name: this.activeName });
    },
  },
};
</script>
<style lang="less" scoped>
.need {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .relative {
    overflow: hidden;
    /deep/.el-tabs {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .el-tabs__content {
        flex: 1;
        .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
  .top-12 {
    top: 12px;
  }
}
</style>
